import React from 'react';
/* import { graphql } from 'gatsby';
import  { getSrc } from 'gatsby-plugin-image';
import Layout from '../containers/layout';
import Rentals from '../containers/rentals';
import SEO from '../components/seo'; */


const RentalsPage = ({  ...props }: any) => {
/*   const { data } = props;
  const coverImage = getSrc(data.page.image) || undefined; */
 
  return (
    <></>
/*     <Layout>
       <SEO
        title={data.page.title}
        image={coverImage}
      />
      <Rentals title={data.page.title} posts={data.allContentfulRentals.edges} /> 
    </Layout> */
  );
};

export default RentalsPage;
/* 
 export const pageQuery = graphql`
  query {
    page:contentfulPage(slug: {eq: "noma"}) {
      id
      title
      body {
        raw
      }
      image {
        gatsbyImageData(width: 1200, height: 630)
      }  
    }
    allContentfulRentals {
      edges {
        node {
          title
          slug
          description {
            description
          }
          body {
            raw
          }
          images {
            gatsbyImageData(width: 600, height: 400, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          }
          rentalCategory {
            name
            slug
          }
        }
      }
    }
    
  }
`;
  */